import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
     <path d="M3311 6249 c-111 -19 -251 -111 -320 -213 -16 -23 -41 -75 -57 -116
l-29 -75 0 -445 0 -445 30 -77 c52 -135 134 -225 261 -288 34 -16 85 -35 114
-40 71 -13 901 -13 960 1 166 38 319 181 370 347 19 60 20 93 20 502 0 409 -1
442 -20 502 -56 181 -222 324 -403 348 -91 12 -853 11 -926 -1z m975 -109
c130 -48 225 -145 265 -270 21 -66 21 -874 0 -940 -30 -92 -121 -204 -199
-244 -99 -51 -97 -51 -572 -51 l-445 0 -73 30 c-68 28 -123 70 -179 137 -28
34 -52 71 -52 81 0 4 -7 30 -16 59 -12 43 -15 122 -15 462 0 466 4 493 77 584
74 90 101 109 228 163 31 13 80 14 519 12 381 -2 412 -4 462 -23z"/>
<path d="M4188 5873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3366 5847 c-65 -36 -74 -62 -78 -225 -2 -78 1 -142 5 -142 5 0 5 -5
2 -10 -4 -6 -6 -95 -5 -197 1 -206 6 -226 66 -264 41 -25 130 -26 164 -2 65
45 71 63 72 210 l0 127 38 -41 c21 -22 44 -43 52 -47 7 -4 24 -13 38 -21 55
-32 122 -12 196 59 26 25 48 46 48 46 1 0 0 -48 -2 -107 -4 -137 6 -174 60
-213 33 -24 51 -30 92 -30 63 0 130 38 146 83 6 18 10 160 10 355 -1 314 -1
327 -22 364 -12 21 -26 38 -30 38 -5 0 -6 6 -2 13 4 6 4 9 -1 5 -4 -4 -20 -1
-36 7 -35 19 -80 19 -122 1 -18 -7 -87 -68 -155 -135 -67 -66 -127 -121 -133
-121 -6 0 -7 5 -4 10 3 6 2 10 -3 10 -4 0 -55 47 -113 106 -146 147 -198 170
-283 121z m87 -369 c52 -51 52 -51 5 -10 -50 44 -100 56 -126 30 -9 -9 -12 -9
-12 0 0 6 8 16 18 21 36 21 63 12 115 -41z m757 42 c11 -7 5 -10 -24 -10 -27
0 -47 -7 -63 -22 -13 -12 -23 -17 -23 -10 0 16 50 52 74 52 11 0 28 -5 36 -10z
m-675 -120 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0
13 -9 23 -20z m466 -33 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7
-18z"/>
<path d="M5623 6203 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5688 6193 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5510 6140 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M5335 6115 c-16 -13 -26 -24 -21 -25 4 0 21 11 38 25 16 14 26 25 21
25 -4 0 -21 -11 -38 -25z"/>
<path d="M5745 6130 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5430 6110 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5800 6117 c0 -3 12 -12 26 -22 15 -9 23 -12 20 -6 -7 11 -46 35 -46
28z"/>
<path d="M5256 6041 c-3 -5 1 -14 8 -20 12 -10 13 -8 9 8 -6 23 -9 25 -17 12z"/>
<path d="M5680 6046 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M5910 6041 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M5305 6030 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5484 6024 c-18 -14 -18 -14 6 -3 31 14 36 19 24 19 -6 0 -19 -7 -30
-16z"/>
<path d="M5520 5980 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M5389 5963 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M5859 5956 l22 -30 -23 -13 c-17 -9 -18 -12 -7 -13 9 0 22 6 28 14 9
11 6 20 -15 42 l-26 29 21 -29z"/>
<path d="M5700 5890 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M5476 5855 c-10 -7 -16 -18 -15 -23 2 -6 12 1 22 14 21 27 19 29 -7
9z"/>
<path d="M5760 5809 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5589 5668 c7 -10 25 -17 40 -17 l26 2 -25 4 c-14 3 -32 10 -40 16
-13 11 -13 10 -1 -5z"/>
<path d="M6470 5613 c-14 -6 -64 -44 -128 -98 -47 -39 -52 -64 -52 -233 0
-153 1 -159 23 -180 27 -26 54 -28 85 -6 20 14 22 23 22 120 0 57 3 104 8 104
4 0 35 -25 70 -56 60 -54 62 -58 62 -104 0 -39 4 -52 22 -64 32 -23 61 -20 87
8 22 23 22 29 19 196 l-3 172 -41 36 c-116 104 -140 118 -174 105z m90 -203
l0 -71 -67 3 -68 3 -1 60 c0 33 1 63 3 68 2 4 33 7 69 7 l64 0 0 -70z"/>
<path d="M7226 5583 c15 -154 15 -153 33 -153 14 0 20 11 25 53 3 28 6 71 6
95 0 41 -1 42 -34 42 -33 0 -34 -1 -30 -37z"/>
<path d="M5817 5592 c-15 -16 -17 -48 -17 -245 0 -214 1 -228 20 -247 24 -24
57 -25 85 -4 17 13 21 30 25 127 l5 112 63 -74 c49 -59 62 -82 62 -108 0 -37
29 -73 59 -73 10 0 30 9 45 21 21 17 26 29 26 66 0 45 -25 113 -41 113 -16 0
-9 22 16 52 29 34 34 111 11 156 -15 29 -65 78 -106 105 -37 26 -230 24 -253
-1z m241 -174 l-3 -63 -62 -3 -63 -3 0 66 0 65 65 0 66 0 -3 -62z"/>
<path d="M6807 5592 c-15 -16 -17 -48 -17 -245 0 -214 1 -228 20 -247 11 -11
31 -20 45 -20 45 0 65 32 66 108 l0 67 62 54 62 53 5 -127 c4 -110 7 -128 24
-141 28 -20 63 -17 86 6 19 19 20 33 20 249 0 254 -2 261 -63 261 -57 0 -62
-10 -67 -122 l-5 -103 -60 0 -60 0 -5 103 c-4 76 -9 105 -20 112 -25 16 -76
11 -93 -8z"/>
<path d="M7384 5599 c-47 -23 -66 -95 -41 -151 14 -31 103 -128 117 -128 6 0
10 36 10 80 l0 80 104 0 c97 0 106 2 120 22 20 29 20 72 0 92 -13 13 -41 16
-152 15 -75 0 -146 -5 -158 -10z"/>
<path d="M4930 5589 c-42 -15 -60 -58 -60 -138 0 -68 1 -71 19 -61 37 19 54
10 145 -80 83 -81 96 -90 130 -90 34 0 47 9 114 73 41 39 84 80 94 90 20 19
53 23 63 7 16 -26 25 -4 25 63 0 82 -22 130 -63 141 -40 10 -66 -6 -152 -88
l-79 -76 -82 80 c-45 44 -86 80 -92 80 -6 0 -16 2 -24 4 -7 3 -24 0 -38 -5z"/>
<path d="M4884 5355 c-13 -19 -15 -45 -12 -131 3 -102 4 -107 31 -130 36 -31
78 -31 114 0 25 21 28 32 31 98 l4 73 -58 58 c-63 63 -85 69 -110 32z"/>
<path d="M5343 5329 c-56 -58 -61 -69 -62 -127 -2 -113 75 -168 148 -106 l31
26 0 115 c0 99 -3 118 -17 129 -30 23 -49 16 -100 -37z"/>
<path d="M7578 5293 l-3 -78 -95 -3 c-127 -3 -137 -6 -145 -42 -16 -73 16 -90
168 -90 129 0 168 14 199 71 23 42 23 76 1 116 -18 31 -97 103 -114 103 -4 0
-9 -35 -11 -77z"/>
<path d="M5618 5093 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6538 4963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5251 4944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6032 4930 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M4968 4943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6218 4943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6278 4933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6031 4864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7455 4839 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M5400 4829 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7211 4734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5578 4723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7357 4709 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M7395 4710 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7550 4709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5388 4703 c39 -2 105 -2 145 0 39 1 7 3 -73 3 -80 0 -112 -2 -72 -3z"/>
<path d="M5748 4703 c39 -2 105 -2 145 0 39 1 7 3 -73 3 -80 0 -112 -2 -72 -3z"/>
<path d="M6133 4703 c31 -2 83 -2 115 0 31 2 5 3 -58 3 -63 0 -89 -1 -57 -3z"/>
<path d="M6928 4703 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
